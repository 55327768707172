<mat-accordion>
	<mat-expansion-panel *ngFor="let inspectionGroup of inspectionGroups"
						(opened)="onExpand(inspectionGroup)"
						(closed)="onCollapse(inspectionGroup)">
		<mat-expansion-panel-header [collapsedHeight]="collapsedPanelHeight" [expandedHeight]="expandedPanelHeight">
			<mat-panel-title
					fxLayout="row"
					fxLayoutAlign="start center"
					fxLayoutGap="20px">
				<ma-recommended-service-severity
						[hidden]="inspectionGroup.IsExpanded"
						[severity]="inspectionGroup.Condition">
				</ma-recommended-service-severity>
				<div class="severity-placeholder"
				     [hidden]="!inspectionGroup.IsExpanded">
				</div>
				<div class="mat-body-2">{{inspectionGroup.Name}}</div>
			</mat-panel-title>
		</mat-expansion-panel-header>

		<ng-container *ngFor="let ii of inspectionGroup.Items; let idx = index;">
			<ma-inspection-report-item [inspectionItem]="ii"></ma-inspection-report-item>
			<div *ngIf="idx < inspectionGroup.Items.length - 1" class="ma-divider"></div>
		</ng-container>

	</mat-expansion-panel>
</mat-accordion>
