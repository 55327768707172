<div class="mat-body-1" fxLayout="column">
	<div [class]="typographyClass">
		<span>
			{{vehicle?.Year}}
			{{vehicle?.Make}}
			{{vehicle?.Model}}
		</span>
		<span>
			{{vehicle?.License}}
		</span>
		<ma-vehicle-color-indicator *ngIf="vehicle?.Color" [color]="vehicle?.Color"></ma-vehicle-color-indicator>
	</div>
	<div *ngIf="vehicle && vehicle.Odometer">
		Odometer: {{vehicle?.Odometer}}
	</div>
	<div *ngIf="vehicle && vehicle.VIN">
		VIN: {{vehicle?.VIN}}
	</div>
</div>
